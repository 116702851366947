<script setup>
import { computed, inject } from 'vue';

const dayjs = inject('dayjs');
const start = computed(() => dayjs(props.shift.start));
const end = computed(() => dayjs(props.shift.end));

const props = defineProps({
    shift: {
        type: Object,
        required: true,
    },
});

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
</script>

<template>
    <div class="flex flex-col">
        <span class="flex items-center gap-1 text-base font-bold text-blue">
            {{ capitalizeFirstLetter(shift.position) }}
            <span v-if="shift.applications.length > 0" class="text-sm font-bold text-blue">
                ({{ shift.applications.length }}/{{ shift.applications.length }})
            </span>
        </span>
        <span class="text-base text-blue">{{ start.format('HH:mm') }} - {{ end.format('HH:mm') }} </span>
    </div>
</template>
